.productTitle {
  font-size: .7rem;
  font-weight: 700;

  a {
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.5s ease-out;

    &:hover {
      color: var(--yc-green)
    }
  }
}

.productSingleTitle {
  font-size: 1.9rem;
  font-weight: 700;
}

.productInCart {
  padding-top: 4rem;
  position: relative;
  min-height: 200px;
  margin-top: -200px;
  background: rgba(33, 47, 62, 0.2);
  -webkit-transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  text-align: center;
  border-radius: 5px;
}

.productInCartNumber {
  position: absolute;
  margin-top: 10px;
  margin-left: .6rem;
  padding-top: .2rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  background-color: var(--mo-primary);
  color: var(--bs-white);
  border-radius: 1rem;
  z-index: 10;
}

.productInCartIcon {
  font-size: 4rem;
  color: var(--bs-green);
}

.bigProducts {
  border-radius: 1rem;

  .productInCart {
    padding-top: 6rem;
    position: relative;
    min-height: 300px !important;
    margin-top: -300px !important;
    border-radius: 1rem;
  }
}

.productImageWrapper {

  img {
    border-radius: 0.5rem;
    box-shadow: rgba(139, 159, 196, 0.2) 0 0.2rem 0.4rem 0;
  }
}

.productImagePrice {
  font-size: 1.5rem;
}

.productSingleWeight {
  margin-left: .8rem;
  font-size: .9rem;
}

.basketContents {
  margin-top: 4rem;
  margin-bottom: 4rem;
  padding: 3rem;
  border-radius: 0.5rem;
  background-color: var(--bs-white);
  box-shadow: rgba(139, 159, 196, 0.2) 0 0.2rem 0.4rem 0;
}

.basketContentsTitle {
  font-size: .7rem;
  font-weight: bold;
}

.basketContentsWeight {
  font-size: .7rem;
  color: var(--bs-gray-800);
}

.donationSelection{
  padding: 1rem 0.5rem;
  background-color: var(--bs-gray-200);
  border-radius: .8rem;
}

.donationSelectionDivider {
  border-left: thin dashed var(--bs-gray-500);
  min-height: 3rem;
}

.donationPaymentSelection {
  padding: 1rem 0.5rem;
    background-color: var(--bs-gray-200);
  border-radius: .8rem;
  border-top: thin dashed var(--bs-gray-200)
}

.donationPaymentDetails {
  padding: 1rem 0.5rem;
  border-radius: .8rem;
  border: thin dashed var(--bs-gray-500)
}

.donationPaymentChoose {
  font-size: .9rem;
  padding: 1rem 0.5rem;
  border-radius: .5rem;
  background-color: var(--yc-green);
  color: var(--bs-white)
}

// Child Product
.productChildSide {
  background-color: #fbebcd;
  padding: 2.7rem 2rem;
}

.productChildTitle {
  font-size: 1.5rem;
  background-color: #fff200;
  color: #00559b;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
}

.productSubChildTitle {
  font-size: 1.5rem;
  background-color: #fff200;
  color: #00559b;
  text-align: center;
}

.productSubChildMaths {
  margin-top: .2rem;
  font-size: 1rem;
  color: var(--bd-dark);
  font-weight: 300;
  text-align: center;
  line-height: 1;
}

.productSubChildItem {
  font-size: 1.5rem;
  color: var(--bd-dark);
  font-weight: bold;
  text-align: center;
  line-height: 1;
}

.productSubChildItemDescription {
  margin-top: -.5rem;
  font-size: .9rem;
  color: var(--bd-dark);
  font-weight: 400;
  text-align: center;
  line-height: 1;
}

.productSubChildItemEqual {
  margin-top: -.6rem;
  font-size: 2rem;
  color: var(--mo-primary);
  font-weight: bold;
  text-align: center;
  line-height: 1;
}