@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&family=Lumanosimo&display=swap');

.charityWrapper {
  padding-bottom: 1rem;
  min-height: 10rem;
  text-align: center;

  h1 {
    margin-top: 3rem;
    font-family: 'Lumanosimo', cursive;
    font-size: 2.2rem;
    color: var(--bs-black);
  }
}

.donationHeading {
  margin-top: 6rem;
  font-size: 3rem;
  font-family: 'Kaushan Script', cursive;
  color: var(--yc-green);
  line-height: 1.3;
}

.donationSubHeading {
  margin-top: 1rem;
  font-size: 2.4rem;
  font-family: 'Kaushan Script', cursive;
  color: var(--mo-primary);
  line-height: 1.3;
}

.donationSideWrapper {
  padding-top: 1rem;
  background-color: var(--mo-highlight-bg);
  border-radius: 1rem;
  min-height: 10rem;
}

.donationHeaderWrapper {
  background-color: #cfb72e;
  color: var(--bs-dark);
  width: 8rem;
  margin: 1rem auto;
  text-transform: uppercase;
  font-size: .8rem;
}

.donationNumberHeading {
  font-size: 2rem;
  color: var(--yc-green);
  line-height: 1.3;
  font-weight: bold;
}

.donationSmallHeading {
  font-size: .9rem;
  color: var(--yc-green);
  line-height: 1.3;
}

.donationCalculateHeading {
  margin-top: 1rem;
}

.giveADonation {
  margin-bottom: 18rem;
  border-radius: 3rem !important;
  background-color: #cfb72e !important;
  border: var(--mo-green) !important;
  color: var(--bs-black) !important;


  &:hover {
    background-color: var(--yc-dark-green) !important;
    color: var(--bs-dark) !important;
    border: var(--bs-gray-900) !important;
  }
}


.ctaChooseDonation {
  padding-top: .3rem;
  background-color: var(--mo-green);
  text-align: center;
  color: var(--bs-white);
  font-size: .9rem;
  min-height: 2rem;
  min-width: 3rem;
  border-radius: .3rem;
  padding-left: .9rem;
  padding-right: .9rem;

  &:hover {
    background-color: var(--bs-gray-800);
  }
}

.ctaDonateFooter {
  padding-bottom: .5rem;
  padding-top: .5rem;
}


@media (min-width: 767.98px) {

  .charityWrapper {
    min-height: 10rem;

    h1 {
      margin-top: 10rem;
      font-size: 3rem;
      font-weight: bold;
      color: #f3da2b;
    }
  }
}


