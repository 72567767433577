@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.2/font/bootstrap-icons.css");

@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;700;800&display=swap');

// kids bla bla
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');


// Variable overrides first
$primary: #900;
$primary-light-blue: rgba(155, 200, 217, .5);
$enable-shadows: false;
$prefix: "mo-";

@import "./styles/root";

@import "bootstrap/scss/bootstrap.scss";

@import "./styles/nav";
@import "./styles/succoth";
@import "./styles/stripe";
@import "./styles/btn";
@import "../src/app/subscription/IbanFormStyles.css";

:root {
  --yc-light-green: rgba(155, 200, 217, .5);
  --yc-dark-green: rgba(155, 200, 217, .9)
}

.navbar-toggler {
  border: #fff !important;
}

.invalid-feedback {
  font-size: .8rem;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

html {
  /* To make use of full height of page*/
  min-height: 100%;
  margin: 0;
}
body {
  min-height: 100%;
  margin: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--yc-background);
  line-height: var(--bs-body-line-height);
  letter-spacing: .03125rem;
  color: var(--yc-green);
}

.loading-logo {
  margin-top: 20%;
}

.invalid-feedback {
  display: inline;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: var(--bs-green);
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.btn-enfant {
  font-size: 1.3rem;
  color: var(--mo-link-color);
  font-weight: 600;
  background-color: #ccb03a;
  border: 1px solid #ccb03a;
}

.checkmark {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0 0 0 var(--bs-green);
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}


@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {

  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0 0 0 30px var(--bs-green);
  }
}

.dropdown-menu {
  --mo-dropdown-bg: #fff;
  --mo-dropdown-border-color: #fff;

  background-color: var(--mo-dropdown-bg);
  border: var(--mo-dropdown-border-width) solid var(--mo-dropdown-border-color);
  border-radius: var(--mo-dropdown-border-radius);
  box-shadow: rgba(139, 159, 196, 0.2) 0 0.2rem 0.4rem 0;
}

@media (max-width: 575.98px) {
  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .show > .nav-link:hover {
    padding-left: 2rem !important;
  }

  .dropdown-menu {
    border: var(--mo-dropdown-border-width) solid var(--mo-dropdown-border-color);
    border-radius: var(--mo-dropdown-border-radius);
    box-shadow: none;
  }
}


body.pessah-info-body {
  background-image: url("../src/app/holidays/pessah/images/pessah_page_info.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

body.succot-body {
  background-image: url("../src/app/holidays/soucoth/images/succoth_bg.jpg") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}





.pessah-info-body {

  .addToCartQty {
    display: none !important;
  }
}

body.pessah-body {
  //background-color: #96d5e6; // 039424300
  background-image: url("../src/app/holidays/pessah/images/pessah_page_2025_b.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 575.98px) {
  body.pessah-body {
    background-color: #e5dcd3;
    background-image: url("../src/app/holidays/pessah/images/pessah_page_mobile.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    background-attachment: fixed;
  }
}

.mandate-acceptance {
  font-size: .9rem;
}


input,
.StripeElement {
  //height: 40px;
  //padding: 10px 12px;

  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow-x: auto;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement {
  height: auto !important;
}

input:focus,
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

body.charity-body-details {
  background-color: #e7dcb8;
  background-image: url("../src/app/splash/images/charity_page_details.jpg");
  background-size: cover;
  background-repeat: no-repeat;
}


body.charity-body {
  background-color: #cfb72e;
  background-image: url("../src/app/splash/images/charity_page.jpg") !important;
  background-size: cover;
  background-repeat: no-repeat;
}

body.solidarite-body {
  background-color: #cfb72e;
}



@media (max-width: 575.98px) {
  body.charity-body {
    background-image: url("../src/app/splash/images/charity_page_mobile.jpg") !important;
  }

  body.succot-body {
    background-image: url("../src/app/holidays/soucoth/images/succoth_picture_mobile.jpg") !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}

