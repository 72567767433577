.sellOption {
  margin-bottom: 1rem;
  padding: 1rem;
  color: var(--bs-white);
  background-color: var(--yc-green);
  //border-radius: 5px;
}

.sellOptionLibre {
  margin-bottom: 1rem;
  padding: 2.2rem;
  color: var(--bs-white);
  background-color: var(--mo-gray);
  border-radius: 5px;

  font-size: 1.4rem;
  font-weight: bold;
}

.sellOptionAmount {
  font-size: 2rem;
  font-weight: bold;
}

.productInCartNumber {
  margin-left: .6rem;
  margin-right: 2rem;
  padding-top: .2rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  background-color: var(--mo-primary);
  color: var(--bs-white);
  border-radius: 1rem;
}

.chosenOptionBg {
  padding: 1.2rem;
  background-color: var(--bs-white);
  border-top: thin solid var(--bs-gray-600);
  border-radius: 1rem;
}

.chosenOptionMonthlyBg {
  padding: 1.2rem;
  background-color: var(--bs-white);
  border-top: 2px solid var(--yc-green);
  border-radius: 1rem;
}
