.navbar-nav {
  --bs-nav-link-padding-x: 0.8rem;
  --bs-nav-link-padding-y: 0.2rem;
}

$yc-green: #1a3e4f;


.btn-donate {
  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--yc-green);
  --bs-btn-border-color: var(--yc-green);
  --bs-btn-border-radius: 1.9rem;
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #{shade-color($yc-green, 10%)};
  --bs-btn-hover-border-color: #{shade-color($yc-green, 10%)};
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color($yc-green, 20%)};
  --bs-btn-active-border-color: #{shade-color($yc-green, 20%)};
}

//
.btn-donate {
  background-color: var(--yc-green);
  border-color: var(--yc-green);
  border-radius: var(--bs-btn-border-radius);
  color: var(--bs-white);
}


.ydc-light {
  border-top: 4px solid $yc-green;
  background-color: #fff !important;
  margin-bottom: 40px;
  padding: 10px 0 0;
  border-bottom: 1px solid #e2e9ee;
  box-shadow: 0 6px 16px 0 rgba(7, 18, 37, .2);
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .show>.nav-link {
  font-size: 16px;

  &:hover {
    background-color:  rgba(155,200,217,.5) !important;
    border-radius: 5px;
    border-radius: 6px;
  }
}


.navbar-light .navbar-nav .active {
  font-size: 16px;
  background-color: beige;
  border-radius: 6px;
}

.brand-logo {

}