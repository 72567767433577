input,
.StripeElement {
    height: 40px;
    padding: 10px 12px;

    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

input:focus,
.StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
    border-color: #fa755a;
    width: 500px;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}