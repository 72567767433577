
.special__product {
  background-color: #EBEBED;
  min-height: 500px;
}


.aside {
  display: block;
  height: 100%;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  background-color: var(--bs-white);
  transform: translate3d(100%, 0, 0);
  -webkit-transition: opacity 0.4s ease-out, visibility 0.4s ease-out, -webkit-transform 0.4s ease-out;
  transition: transform 0.4s ease-out, opacity 0.4s ease-out, visibility 0.4s ease-out, -webkit-transform 0.4s ease-out;
  visibility: hidden;
  width: 360px;
  will-change: transform, opacity, visibility;
  z-index: 1072;
}

.has-aside {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.overlay {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  background: rgba(33, 47, 62, 0.7);
  cursor: e-resize;
  display: block;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  visibility: hidden;
  z-index: 1000;
}

.has-overlay {
  opacity: 1;
  visibility: visible;
}