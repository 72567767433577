.Pessah {
  margin-top: 1rem;
}

.SouccotCta {
  margin-top: 1px;
}

.DonateLead {
  margin-top: 3rem;
}

.HolidayRed {
  color: var(--mo-primary);
}

.Heading {
  font-family: 'Water Brush', cursive;
  font-weight: 200;
  font-size: 9rem;
  line-height: .6;

  color: var(--mo-primary);
}

.Joyeux {
  margin-top: 1rem;
  font-size: 1.5rem;
  color: var(--mo-black);
  font-family: 'Amatic SC', cursive;
}

.Year {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1;
  color: var(--mo-primary);
}

.holidayPack {
  border-radius: 1rem;

  margin-top: 2rem;
  margin-bottom: 3rem;
  padding-top: 4rem;
  border-bottom: 1px solid #e2e9ee;
  box-shadow: 0 6px 10px 0 rgba(7, 18, 37, .2);
  background-color: #faf9e8;
  background-image: url("./images/pessh_info.png");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: bottom left;
}

.HolidayPackHeader {
  margin-top: 2rem;
  font-size: 1.4rem;
  font-weight: bold;
  line-height: 1;
  color: var(--mo-primary);
}

.holidayFooter {
  margin-bottom: 2rem;
  padding: 2rem;
  border-radius: 1rem;
  width: 100% !important;
}

.HolidayPackSub {
  font-size: .8rem;
  font-weight: bold;
  color: var(--mo-dark);
}

.HolidayProduct {
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--mo-dark);
}

.HolidayProductPrice {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--mo-primary);
}

.HolidayPackListHeader {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  color: var(--bs-body-color);
}

.HolidayPackListTotal {
  margin-top: 1rem;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1;
  color: var(--mo-primary);
}

.HolidayPackListTotalSub {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
  color: var(--mo-dark);
}

.holidayPackBottom {
  background-color: var(--mo-white);
  border-radius: 1rem;

  margin-bottom: 70px;
  padding: 2rem;
  border-bottom: 1px solid #e2e9ee;
  box-shadow: 0 6px 10px 0 rgba(7, 18, 37, .2);
}

.RoshHaShanaCTA {
  font-size: .8rem;
  text-transform: uppercase;
}


@media (max-width: 575.98px) {

  .Joyeux {
    margin-top: 1% !important;
  }

  .Heading {
    font-weight: 200;
    font-size: 7rem;
  }

  .HolidayProductPrice {
    font-size: .8rem;
  }

  .HolidayProduct {
    font-size: .8rem;
  }

  .HolidayPackHeader {
    margin-top: .2rem;
    font-size: .9rem;
  }

  .HolidayPackListTotal {
    margin-top: 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    line-height: 1;
    color: var(--mo-primary);
  }

}