.giveADonation {
  border-radius: 3rem !important;
  background-color: var(--mo-warning) !important;
  border: var(--mo-green) !important;
  color: var(--bs-dark) !important;

  &:hover {
    background-color: var(--yc-dark-green) !important;
    color: var(--bs-dark) !important;
    border: var(--bs-gray-900) !important;
  }
}

.navProducts {
  font-weight: 500;
  text-transform: uppercase;
  color: black;
}


@media (max-width: 575.98px) {
  .giveADonation {
    margin-bottom: 2rem;
    width: 300px;
  }
}