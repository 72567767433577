@import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Water+Brush&display=swap');

.RoshHaShana {
  min-height: 30rem;
  background-image: url("../images/rosh_hashana_image.jpg");
  background-repeat: no-repeat;
  object-fit: cover;
  box-shadow: rgba(139, 159, 196, 0.2) 0 0.2rem 0.4rem 0;
  border: solid var(--mo-gray-300) thin;
  border-radius: .6rem;
}

.Heading {
  font-weight: 200;
  font-size: 5rem;
  line-height: 1;

  color: var(--bs-dark);

  font-family: 'Water Brush', cursive;
}

.Year {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1;
  color: #b75d5b;
}

.Joyeux {
  font-size: 1.5rem;
  color: #b75d5b;
  font-family: 'Amatic SC', cursive;
}

.YearFrench {
  font-size: .9rem;
}

.Hebrew {
  font-size: 2.6rem;
  font-family: 'Amatic SC', cursive;
  color: var(--yc-green)
}

.RoshHaShanaCTA {
  background-color: #8b4049;
  border-color: #8b4049;
  font-size: .8rem;
  text-transform: uppercase;
}

.DonateLead {
  margin-top: 19rem;
}

.DonateCTA {
  margin-top: 1rem;
}


@media (max-width: 575.98px) {

  .RoshHaShana {
    padding-top: 5rem;
    padding-bottom: 1.5rem;
    background-image: url("../images/rosh_hashana_mobile.jpg");
    background-size: 500px;
  }

  .DonateLead {
    margin-top: 1.2rem;
  }
}