.btn-danger {
  --mo-btn-color: #fff;
  --mo-btn-bg: #8b4049;
  --mo-btn-border-color: #8b4049;
  --mo-btn-hover-color: #fff;
  --mo-btn-hover-bg: #7d3a42;
  --mo-btn-hover-border-color: #7d3a42;
  --mo-btn-focus-shadow-rgb: 225, 83, 97;
  --mo-btn-active-color: #fff;
  --mo-btn-active-bg: #97535b;
  --mo-btn-active-border-color: #97535b;
  --mo-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --mo-btn-disabled-color: #fff;
  --mo-btn-disabled-bg: #a2666d;
  --mo-btn-disabled-border-color: #a2666d;
}

.btn-success {
  font-weight: 400;
  font-size: .9rem;
  background-color: var(--yc-green);
  border: var(--yc-light-green);
  text-transform: uppercase;

  &:hover {
    background-color: var(--yc-light-green);
    color: var(--yc-green);
  }
}