.charityTeaserWrapper {
  background-color: rgba(31, 124, 187, .8);
  background-image: url('./images/support_child.jpg');

  background-repeat: no-repeat;
  background-size: 100% 100%;


  border-radius: 1rem;
  min-height: 45rem;
  margin-bottom: 2rem;

  h1 {
    font-size: 2.8rem;
    //color: var(--bs-dark);
    font-family: "Kaushan Script", cursive;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    color: #00589f;
  }

  h3 {
    font-size: 2rem;
    font-family: "Kaushan Script", cursive;
    font-weight: 400;
    font-style: normal;
    text-align: center;
    color: var(--mo-primary);
  }

}

.charityTeaserWords {
  text-align: center;
  padding: 9rem 1rem 1rem;
  margin-right: 3rem;
  margin-top: 3rem;
  font-weight: bold;

  h1 {
    color: #ffc107;
    font-size: 3.5rem;
  }
}


@media (max-width: 576px) {
  .charityTeaserWrapper {
    background-image: linear-gradient(to left, rgba(245, 246, 252, 0.0), rgba(31, 124, 187, .8)), url('./images/charity_page_home_mobile.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: .1px;
    min-height: 20rem;

    h1 {
      font-size: 2.5rem;
      line-height: 1;
    }
  }

  .charityTeaserWords {
    text-align: center;
    padding: 1rem;
    margin-right: 3rem;
    margin-top: 3rem;
    font-weight: bold;
  }



}