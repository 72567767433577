.succoth__page {
  margin-top: -5rem !important;
  padding-top: 8rem !important;
  background-color: #dff2f0;
  //background-image: url("./images/succot.jpg");
  //background-repeat: no-repeat;
  //background-attachment: fixed;
  //background-position: top;
}

@media (max-width: 575.98px) {

  .succoth__page {
    margin-top: -5rem !important;
    padding-top: 2rem !important;
  }
}
