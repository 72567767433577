.Donate {
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.DonateInner {
  padding: 1.2rem;
  display: flex;
  background-color: var(--bs-body-color);
  color: var(--bs-white);
  border-radius: .5rem;
}

.productInCartNumber {
  margin-left: .6rem;
  margin-right: 2rem;
  padding-top: .2rem;
  width: 2rem;
  height: 2rem;
  text-align: center;
  background-color: var(--mo-primary);
  color: var(--bs-white);
  border-radius: 1rem;
}
